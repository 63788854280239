import React from "react";
import { useContext, useState, useEffect } from "react";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import { Store } from "../context/store";
import BarLoader from "react-spinners/BarLoader";
import { useNavigate } from "react-router-dom";
// import Img1 from "../assets/images/careers.webp";

function Test() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [error, setError] = useState("");
  let [loading, setLoading] = useState(false);
  let [questions, setQuestions] = useState([]);
  let navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(0);
  let [response, setResponse] = useState([]);
  let id = localStorage.getItem("lag");
  let [disable, setDisable] = useState(false);

  useEffect(() => {
    loadQuestions();
    check();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = (e) => {
      if (document.hidden) {
        // User switched to a different tab or minimized the browser

        setTimeLeft(0);
        alert(
          "You have switched tabs or minimized the browser, the system will automatically submit for you. Wishing you all the best!"
        );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [disable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 1) {
        setTimeLeft(timeLeft - 1);
      } else {
        handleFormSubmit();
        clearInterval(timer);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const style = {
    userSelect: "none" /* Standard syntax (supported by most browsers) */,
    MozUserSelect: "none" /* Firefox */,
    WebkitUserSelect: "none" /* Safari and Chrome */,
    msUserSelect: "none" /* Internet Explorer/Edge */,
  };

  let check = () => {
    if (!id) {
      navigate("/aptitude/login");
    } else {
      let url = mainUrl + "/user/" + id;
      fetch(url)
        .then((response) => response.json())
        .then((res) => {
          if (res.timer === 0 || res.takenTest === true) {
            navigate("/aptitude/login");
          } else {
            setTimeLeft(res.timer);
          }
        })
        .catch((error) => {
          navigate("/aptitude/login");
        });
    }
  };

  let loadQuestions = () => {
    let url = mainUrl + "/assign/" + id;
    fetch(url)
      .then((response) => response.json())
      .then((res) => {
        setQuestions(res.data);
        setLoading(false);
        const responses = res.data.map((item) => ({
          _id: item._id,
          userAnswer: "",
        }));
        setResponse(responses);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFormSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    const url = `${mainUrl}/submit/${id}`;
    const data = {
      response,
    };

    try {
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const res = await response.json();
        if (res.msg === "User already submitted !") {
          localStorage.removeItem("lag");
        }
        localStorage.removeItem("lag");
        setDisable(true);
        setResponse([]);
        _setErrorAndLoading(res.msg, 2000, 3000, () => {
          check();
        });
      } else {
        const err = await response.json();
        setErrorAndLoading(err.msg, 3000, 4000);
      }
    } catch (error) {
      setErrorAndLoading("An error occurred", 3000, 4000);
    }
  };

  const setErrorAndLoading = (
    errorMessage,
    loadingTimeout,
    clearErrorTimeout
  ) => {
    setError(errorMessage);

    const t1 = setTimeout(() => {
      setLoading(false);
      clearTimeout(t1);

      const t2 = setTimeout(() => {
        setError("");
        clearTimeout(t2);
      }, clearErrorTimeout);
    }, loadingTimeout);
  };

  const _setErrorAndLoading = (
    errorMessage,
    loadingTimeout,
    clearErrorTimeout,
    callback
  ) => {
    setError(errorMessage);

    const t1 = setTimeout(() => {
      setLoading(false);
      clearTimeout(t1);

      const t2 = setTimeout(() => {
        setError("");
        clearTimeout(t2);

        if (typeof callback === "function") {
          callback(); // Call the callback function after clearing the error
        }
      }, 700);
    }, loadingTimeout);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const selectQuestionAnswer = (a, b) => {
    const updatedResponse = response.map((item) => {
      if (item._id === a) {
        return { _id: item._id, userAnswer: b };
      } else {
        return item;
      }
    });
    setResponse(updatedResponse);
  };

  return (
    <>
      <div className="">
        <div className="container-fluid" style={style}>
          <div className="quiz">
            <div className="col-md-12">
              <div className="nav">
                <h2>
                  <span id="schooly">OGTP 2023</span>
                </h2>

                <div className="">
                  <p>Timer</p>
                  <div className="time">
                    <h2 id="time">{formatTime(timeLeft)}</h2>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "50px" }}>
                <div className="container ">
                  <div className="m-5" id="par">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <center className="center">
                            {questions?.map((question, questionIndex) => (
                              <div key={questionIndex}>
                                <h3>
                                  {questionIndex + 1}.{" "}
                                  {question.questionId.question}
                                </h3>
                                <p></p>
                                <div className="row">
                                  <div className="col-md-6">
                                    <form name="theFormName">
                                      {question.questionId.options.map(
                                        (answer, answerIndex) => (
                                          <label
                                            className="section"
                                            for={answer._id}
                                          >
                                            <input
                                              type="radio"
                                              name="theRadioGroupName"
                                              key={answerIndex}
                                              onClick={() =>
                                                selectQuestionAnswer(
                                                  question._id,
                                                  answer.letter
                                                )
                                              }
                                              value={answer.letter}
                                              id={answer._id}
                                            />
                                            <p for={answer._id}>
                                              {answer.letter} {answer.value}
                                            </p>
                                            <br />
                                          </label>
                                        )
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="down">
                      <div className="col-md-3 share">
                        <p>
                          <span id="nam"></span> <br />
                          Goodluck!!!
                        </p>
                      </div>
                      <h3>{error}</h3>
                      {loading ? (
                        <BarLoader width={"100px"} color="white" />
                      ) : (
                        <button
                          id="next"
                          onClick={handleFormSubmit}
                          disabled={disable}
                        >
                          Submit{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="currentColor"
                            className="bi bi-arrow-bar-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- footer --> */}
        <Footer />
      </div>

      <GoToTop />
    </>
  );
}

export default Test;
