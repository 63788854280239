import React, { useContext, useState, useEffect } from "react";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import { Col, Row } from "react-bootstrap";
import { Store } from "../context/store";
import BarLoader from "react-spinners/BarLoader";
import { useNavigate } from "react-router-dom";

function Login() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [error, setError] = useState("");
  let [loading, setLoading] = useState(false);
  let [yearOfNysc, setNysc] = useState("");
  let [isSmallScreen, setIsSmallScreen] = useState(false); // Track if the screen is small
  let navigate = useNavigate();

  useEffect(() => {
    // Check screen size on mount and resize
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust threshold as needed
    };

    checkScreenSize(); // Initial check

    // Add event listener for screen resize
    window.addEventListener("resize", checkScreenSize);

    return () => {
      // Cleanup event listener on component unmount
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (phone.trim() === "" || email.trim() === "") {
      setError("Please fill missing field(s)!!!");
      clearErrorAndLoading(3000);
      return;
    }

    const url = `${mainUrl}/login`;
    const data = { email, phone, yearOfNysc };

    try {
      const response = await fetch(url, {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const res = await response.json();
        if (res.msg === "User Has Already Participated in the Assessment") {
          setErrorAndLoading(res.msg, 3000, 4000);
        } else {
          localStorage.setItem("lag", res.id);
          setEmail("");
          setPhone("");
          setErrorAndLoading(res.msg, 3000, 4000);
          navigate("/aptitude/test");
        }
      } else {
        const err = await response.json();
        setErrorAndLoading(err.msg, 3000, 4000);
      }
    } catch (error) {
      setErrorAndLoading("An error occurred", 3000, 4000);
    }
  };

  const clearErrorAndLoading = (timeout) => {
    const t1 = setTimeout(() => {
      setError("");
      setLoading(false);
      clearTimeout(t1);
    }, timeout);
  };

  const setErrorAndLoading = (errorMessage, loadingTimeout, clearErrorTimeout) => {
    setError(errorMessage);
    const t1 = setTimeout(() => {
      setLoading(false);
      clearTimeout(t1);

      const t2 = setTimeout(() => {
        setError("");
        clearTimeout(t2);
      }, clearErrorTimeout);
    }, loadingTimeout);
  };

  return (
    <>
      <div>
        <div className="container-fluid">
          {isSmallScreen ? (
            <div className="alert alert-warning text-center mt-5">
              <h4>⚠️ Test Not Supported on Mobile Devices</h4>
              <p>
                Please use a laptop or desktop to take the test for the best experience.
              </p>
            </div>
          ) : (
            <div className="signup">
              <div className="col-md-12">
                <div>
                  <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                      <div className="one"></div>
                    </div>
                    <div className="col-md-8 p-5">
                      <div className="navs">
                        <span id="linn">Welcome to your Aptitude Test!</span>
                      </div>
                      <div className="inp">
                        <div className="my-5">
                          <h3>Here are your instructions:</h3>
                          <ol>
                            <li style={{ fontWeight: 700 }}>
                              Once you start, please refrain from switching between screens or tabs to avoid potential disqualification.
                            </li>
                            <li>
                              It is advisable not to take this test using a mobile device for the best experience.
                            </li>
                            <li>Read each question carefully before answering.</li>
                            <li>You have a limited time for your test.</li>
                            <li>Click 'Submit' when you are done.</li>
                            <li>Any attempts to cheat will result in disqualification.</li>
                            <li>The use of any external resources will result in disqualification.</li>
                            <li>The test is for <b>20 minutes</b></li>
                            <li>Login using your email address and phone number</li>
                            <li>Good luck, and let's get started!</li>
                          </ol>
                        </div>
                        <form onSubmit={handleFormSubmit}>
                          <Row>
                            <Col md="6">
                              <input
                                type="text"
                                placeholder="Enter Valid Email Address"
                                id="emmail"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                value={email}
                              />
                            </Col>
                            <Col md="6">
                              <input
                                type="tel"
                                placeholder="Enter Phone Number"
                                id="noom"
                                name="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                value={phone}
                              />
                            </Col>
                            <Col
                              md="6"
                              style={{
                                margin: "auto 0",
                                display: "flex",
                                justifySelf: "center",
                              }}
                            >
                              <select
                                id="noom"
                                name="yearOfNysc"
                                onChange={(e) => setNysc(e.target.value)}
                                required
                                value={yearOfNysc}
                              >
                                <option value="">Year of NYSC</option>
                                {Array.from({ length: 14 }, (_, i) => {
                                  const year = 2023 - i;
                                  return (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          {loading ? (
                            <BarLoader width={"100%"} />
                          ) : (
                            <button className="btn3" type="submit">
                              <span>Start</span>
                            </button>
                          )}
                        </form>
                        <p id="msg">{error}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
      <GoToTop />
    </>
  );
}

export default Login;
