import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/about.css";
import "./assets/css/career.css";
import "./assets/css/contact.css";
import "./assets/css/faq.css";
import "./assets/css/gallery.css";
import "./assets/css/purpose-card.css";
import "./assets/css/register.css";
import "./assets/css/quiz.css";
import "bootstrap/dist/css/bootstrap.min.css";
import StoreContext from "./context/store";
import Home from "./pages/Home";
import About from "./pages/About";
import Commodities from "./pages/Commodities";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
// import Career from "./pages/Career";
import Register from "./pages/Register";
import InquiryForm from "./pages/Survey";
import Login from "./pages/Login";
import Test from "./pages/Test";

function App() {
  return (
    <StoreContext>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/metals" element={<Commodities />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/career" element={<Career />} /> */}
          {/* <Route path="/apply" element={<Register />} /> */}
          <Route path="/survey" element={<InquiryForm />} />
          <Route path="/aptitude/login" element={<Login />} />
          <Route path="/aptitude/test" element={<Test />} />
        </Routes>
      </Router>
    </StoreContext>
  );
}

export default App;
